import { css } from 'astroturf';
import React from 'react';

import PageHeader from './PageHeader';
import TailwindBeatingHeart from './TailwindBeatingHeart';
import FeatureList from './TailwindFeatureList';
import Testimonials from './TailwindTestimonials';

const styles = css`
  .punchout {
    background: linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    font-weight: 700;
  }
`;

function Tailwind() {
  return (
    <>
      <PageHeader>
        <TailwindBeatingHeart />
        <h1>
          <span className={styles.punchout}>Polypane</span> is the fastest way to develop amazing sites with Tailwind
          CSS
        </h1>
        <h2>
          A stand-alone browser for web developers and designers that makes you five times as productive and helps you
          build better websites and apps.
        </h2>
      </PageHeader>
      <FeatureList />
      <Testimonials />
    </>
  );
}

export default Tailwind;
