import React, { Component } from 'react';
import CTA from '../components/CTA';
import SEO from '../components/SEO';
import Tailwind from '../components/Tailwind';
import Layout from '../layout';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Developing Tailwind CSS sites with Polypane"
          description="The perfect browser for developing websites with Tailwind CSS. Polypane makes developing Tailwind CSS sites fast, easy and fun."
          keywords={['']}
          pathname={this.props.location.pathname}
        />
        <Tailwind />
        <CTA
          title="Build Tailwind CSS sites. 5x as fast."
          text="Polypane makes developing Tailwind CSS sites fast, easy and fun."
        />
      </Layout>
    );
  }
}

export default Page;
