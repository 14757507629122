import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import BeatingHeart from './BeatingHeart';

function Github() {
  return (
    <BeatingHeart>
      <StaticImage src="../../assets/images/tailwind.svg" alt="" objectFit="contain" placeholder="none" width={150} />
    </BeatingHeart>
  );
}

export default Github;
