import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';

import Feature from './Feature';
import FeatureCloud from './FeatureCloud';
import FeatureVideo from './FeatureVideo';
import Link from './link';

const styles = css`
  .featureList {
    padding: 0 0 5rem;
    text-align: center;

    & small {
    }

    & > h2 {
      padding: 5rem 2rem 0rem;
      font-weight: 800;
      color: #000;
      font-size: calc(16px + 0.6vw);
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }
    & > p {
      margin: 5rem auto 1rem;
      padding: 0 2rem;
      max-width: 50rem;
      font-size: calc(20px + 0.8vw);
      font-weight: 400;
      opacity: 0.6;
      color: #000;

      &.nopad {
        margin: 0 auto;
      }
      & > small {
        font-size: calc(14px + 0.25rem);
      }
    }
  }
`;

// zooming

function FeatureList() {
  return (
    <StaticQuery
      query={photoquery}
      render={(data) => (
        <section className={styles.featureList}>
          <FeatureVideo screenshot={data.panesVideo} type="light" brow="Responsive design">
            <h3>Develop in multiple viewports at the same time</h3>
            <p>
              Polypane shows your site in multiple viewports at the same time, so you never have you resize your
              browser.
            </p>
            <p>
              All your actions like scrolling, <strong>hovering</strong>, clicking and keyboard input are synced between
              all viewports.
            </p>
          </FeatureVideo>

          <Feature gatsbyImg={data.breakpoints} type="light" full brow="Workspaces">
            <h3>Built-in Tailwind CSS breakpoints</h3>
            <p>Use the built-in Tailwind CSS breakpoints so you instantly get an overview of your site.</p>
            <p>
              Using custom breakpoints? No problem, Polypane automatically detects those and makes panes with a single
              click.
            </p>
          </Feature>

          <FeatureVideo screenshot={data.devtoolsVideo} full type="light" brow="Element editor">
            <h3>Add, edit and remove classes at lightning speed.</h3>
            <p>
              Apply Tailwind CSS classes to elements <strong>in all panes at once</strong>. See the effect on all
              screens at the same time.
            </p>
            <p>
              Polypane automatically suggests classes from your CSS and HTML, and expands the class editor so you can
              see all your classes at a glance.
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.browseVideo} type="light" brow="Side browser">
            <h3>Always have the Tailwind CSS docs at hand</h3>
            <p>
              With the Tailwind CSS documentation or your favorite Tailwind CSS cheatsheet in our side browser you never
              have to look far when you're looking something up.
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.darkmode} type="light" large brow="Media Query emulation">
            <h3>Dark mode media query ready</h3>
            <p>
              Develop your site in light and dark mode at the same time, both in view and synced together. There is no
              faster way to build sites with dark and light mode.
            </p>
            <p>Also works for reduced motion, color gamut and reduced data media queries!</p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.liveReloadVideo} type="light" brow="Live reloading">
            <h3>Let us reload the CSS.</h3>
            <p>
              With built-in live reloading it doesn't matter if you have a hot-reloading setup or not. Polypane gives
              you live updating CSS without page refresh regardless of your stack.
            </p>
          </FeatureVideo>

          <Feature gatsbyImg={data.devex} full type="light" brow="Chromium devtools included">
            <h3>Your favorite DevTools extensions</h3>
            <p>
              Install and use your favorite devtools, with support for <strong>React DevTools</strong>,{' '}
              <strong>Vue.js DevTools</strong>, <strong>Redux DevTools</strong>, Angular, Svelte and many more.
            </p>
          </Feature>

          <Feature screenshot={data.tailwindmade} type="light" borderRadius={12} brow="Case study">
            <h3>How Red Pixel Themes develops three times faster with Polypane</h3>
            <p>
              "The biggest benefit we got by using Polypane was <i>way shorter dev time</i>. What we used to do in 1
              hour in Chrome took 20 minutes in Polypane. <strong>It was that good</strong>."
            </p>

            <p>
              <Link to="/blog/how-red-pixel-themes-uses-polypane/" className={styles.primaryButton}>
                <span>Full case study</span>
              </Link>
            </p>
          </Feature>

          <h2>Polypane has hundreds of features:</h2>
          <FeatureCloud type="light" />
        </section>
      )}
    />
  );
}

export default FeatureList;

const photoquery = graphql`
  query FeaturesPageTailwindCSSQuery {
    panesVideo: file(relativePath: { eq: "videos/tailwindviewports.mp4" }) {
      publicURL
    }
    breakpoints: file(relativePath: { eq: "images/tailwindcss/breakpoints.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    darkmode: file(relativePath: { eq: "videos/marketers/darkmode.mp4" }) {
      publicURL
    }
    tailwindmade: file(relativePath: { eq: "images/tailwindcss/cover.svg" }) {
      publicURL
    }
    browseVideo: file(relativePath: { eq: "videos/tailwind-docs.mp4" }) {
      publicURL
    }
    syncVideo: file(relativePath: { eq: "videos/syncing.mp4" }) {
      publicURL
    }
    devtoolsVideo: file(relativePath: { eq: "videos/classeditor.mp4" }) {
      publicURL
    }
    liveReloadVideo: file(relativePath: { eq: "videos/live-reload.mp4" }) {
      publicURL
    }
    devex: file(relativePath: { eq: "images/devtools-extensions.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;
