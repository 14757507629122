import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import Person from './testimonials/Person';
import Testimonial from './testimonials/Testimonial';

const styles = css`
  .testimonals {
    position: relative;
    padding: 5rem 0;
    text-align: center;
    color: #000;

    & h2 {
      font-size: calc(20px + 0.8vw);
      font-weight: 800;
      opacity: 1;
    }
  }

  .quotes {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: flex-start;
    flex-wrap: wrap;

    & > div {
      min-width: 20rem;
    }

    @media (min-width: 60rem) {
      padding-left: 1rem;
      padding-right: 1rem;
      justify-content: center;
    }
  }
`;

function Testimonals() {
  const saraQuote = (
    <p>
      It is convenient seeing your work in multiple responsive views at the same time.{' '}
      <strong>
        Love it, <i>💯</i>
      </strong>
    </p>
  );

  const sergioQuote = (
    <p>
      Using Polypane and woah this is awesome! It’s <strong>really slick and fast!</strong> I was just 5 minutes in and
      I already fixed a bug.
    </p>
  );

  const vivianQuote = (
    <p>
      This thing is FAST and the synchronized scrolling works amazingly. <strong>Prepare to be impressed.</strong>
    </p>
  );

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => (
        <div className={styles.testimonals}>
          <h2>Developers and designers love Polypane</h2>
          <p>Here's what they have to say:</p>
          <div className={styles.quotes}>
            <Testimonial quote={saraQuote}>
              <Person
                image={data.sara}
                name="Sara Soueidan"
                url="https://www.sarasoueidan.com/"
                desc="Independent front-end UI engineer, Speaker/Trainer"
              />
            </Testimonial>

            <Testimonial quote={vivianQuote}>
              <Person
                image={data.vivian}
                name="Vivian Guillen"
                url="https://www.getrevue.co/profile/tailwind-weekly"
                desc={<>Frontend Developer &amp; Curator at Tailwind Weekly</>}
              />
            </Testimonial>

            <Testimonial quote={sergioQuote}>
              <Person
                image={data.sergio}
                name="Sergio Mattei"
                url="https://getmakerlog.com"
                desc="Founder at Makerlog"
              />
            </Testimonial>
          </div>
        </div>
      )}
    />
  );
}

export default Testimonals;

const detailsQuery = graphql`
  query TailwindTestimonalsQuery {
    sara: file(relativePath: { eq: "images/sara.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    sergio: file(relativePath: { eq: "images/sergio.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    vivian: file(relativePath: { eq: "images/vivian.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
  }
`;
